import type {NavigationGuardNext, RouteLocationNormalized} from 'vue-router';
import {hasSubdomain} from '@/utils/hosts/subdomain';

export const subdomainGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  if (to.meta.noSubdomainRequired) {
    next();
    return;
  }

  if (hasSubdomain()) {
    next();
    return;
  }

  next({
    name: 'no-subdomain',
  });
};
