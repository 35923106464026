<template>
  <slot></slot>
</template>

<script setup lang="ts">
import type {Language} from '@/types/i18n.types';
import type {PortalMessages, ValueOf} from '@myparcel-frontend/types';
import {PortalEvents} from '@myparcel-frontend/types';
import {useEventListener} from '@vueuse/core';
import {onMounted} from 'vue';
import {useI18n} from 'vue-i18n';
import {useRouter} from 'vue-router';
import {useBranding} from '@/composables/useBranding/useBranding';
import {useConfig} from '@/composables/useConfig/useConfig';
import {useReturnReasons} from '@/composables/useReturnReasons/useReturnReasons';
import {loadAndSetLanguage} from '@/i18n';

const {setBranding, setFields, getBranding} = useBranding();
const {isDemo, isAllowedEmbedReferrer, mobilePreview, subdomain} = useConfig();
const {settings: returnSettings} = useReturnReasons();
const router = useRouter();
const {locale} = useI18n({useScope: 'global'});

/**
 * For more information the receiving and sending events in the frontoffice you can read:
 * https://myparcelnl.atlassian.net/wiki/spaces/FG/pages/480182276/Frontofffice+embedding
 */

useEventListener(window, 'message', async (event: MessageEvent<PortalMessages>) => {
  if (!isAllowedEmbedReferrer.value) return;

  // This is the only event that can be triggered when demo mode is off
  if (event.data.event === PortalEvents.InitDemo) {
    isDemo.value = true;
    window.parent.postMessage({event: PortalEvents.DemoReady}, event.origin);
    return;
  }

  // Prevent all messages from altering the frontoffice when demo mode is not enabled
  if (!isDemo.value) {
    return;
  }

  if (event.data.event === PortalEvents.UpdateBranding) {
    // Fetch the default branding when the consumer portal is disabled
    if (event.data.value.use_consumer_portal) {
      setBranding(event.data.value);
    } else {
      void getBranding({
        subdomain: '',
        language: locale.value,
      });
    }

    return;
  }

  if (event.data.event === PortalEvents.Navigate) {
    void router.push(event.data.value);
    return;
  }

  if (event.data.event === PortalEvents.UpdateFields) {
    setFields(locale.value, event.data.value);
    return;
  }

  if (event.data.event === PortalEvents.UpdateReturnSettings) {
    returnSettings.value = event.data.value;
    return;
  }

  if (event.data.event === PortalEvents.MobileDisplayMode) {
    mobilePreview.value = event.data.value;
    return;
  }

  if (event.data.event === PortalEvents.Locale) {
    await Promise.all([
      loadAndSetLanguage(event.data.value as ValueOf<typeof Language>),
      getBranding({
        subdomain: subdomain.value,
        language: event.data.value,
      }),
    ]);

    locale.value = event.data.value;
    const html = document.querySelector('html');

    if (html) {
      html.setAttribute('lang', event.data.value);
    }
  }
});

onMounted(() => {
  // Send a message to the parent that the portal is ready to receive messages.
  window.parent.postMessage({event: PortalEvents.PortalLoaded}, '*');
});
</script>
