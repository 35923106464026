<template>
  <PlatformProvider>
    <I18nProvider>
      <BrandingProvider>
        <DemoService />
        <div
          class="flex h-full max-w-[800px] mx-auto my-0"
          :class="{
            'group/app mobile-active !max-w-[460px]': isDemo && mobilePreview,
          }">
          <RouterView v-slot="{Component}">
            <div class="flex flex-1 flex-col p-4 w-full">
              <div class="flex flex-row-reverse justify-between mb-4 w-full">
                <LanguageToggle class="max-w-[180px] self-end" />
                <RouterLink
                  v-if="route.name !== 'home'"
                  v-slot="{navigate}"
                  :to="{name: 'home'}"
                  custom>
                  <Button
                    variant="primary-basic"
                    type="button"
                    class="flex gap-1"
                    @click="navigate">
                    <IconBase :size="IconSize.Medium">
                      <Home />
                    </IconBase>
                    {{ $t('home') }}
                  </Button>
                </RouterLink>
              </div>

              <component
                :is="Component"
                class="grow" />

              <Footer class="mt-6" />
            </div>
          </RouterView>
        </div>
        <NotificationContainer />
      </BrandingProvider>
    </I18nProvider>
  </PlatformProvider>
</template>

<script setup lang="ts">
import {NotificationContainer} from '@myparcel-frontend/notifications';
import {usePublicSdk} from '@myparcel-frontend/sdk';
import {Button, IconBase, Home, IconSize} from '@myparcel-frontend/ui';
import {RouterLink, RouterView, useRoute} from 'vue-router';
import I18nProvider from './providers/I18nProvider/I18nProvider.vue';
import Footer from '@/components/Footer/Footer.vue';
import LanguageToggle from '@/components/LanguageToggle/LanguageToggle.vue';
import {useConfig} from '@/composables/useConfig/useConfig';
import {useEnv} from '@/composables/useEnv/useEnv';
import BrandingProvider from '@/providers/BrandingProvider/BrandingProvider.vue';
import PlatformProvider from '@/providers/PlatformProvider/PlatformProvider.vue';
import DemoService from '@/services/DemoService/DemoService.vue';

const {initSdk} = usePublicSdk();
const {environment} = useEnv();
const {mobilePreview, isDemo} = useConfig();
const route = useRoute();

initSdk(environment.value.apiUrl);
</script>
