<template>
  <div v-if="branding">
    <slot />
  </div>
</template>

<script setup lang="ts">
import {onBeforeMount, watch} from 'vue';
import {useI18n} from 'vue-i18n';
import {useBranding} from '@/composables/useBranding/useBranding';
import {useConfig} from '@/composables/useConfig/useConfig';
import {useBrandingStore} from '@/store/brandingStore/brandingStore';
import {generateBrandingColors} from '@/utils/colors/colors';
import {recolorFavicon} from '@/utils/favicon/recolorFavicon';
import {hasSubdomain} from '@/utils/hosts/subdomain';

const {branding, getBranding} = useBranding();
const {subdomain, config} = useConfig();
const brandingStore = useBrandingStore();
const {locale} = useI18n();

watch(branding, () => {
  if (branding.value?.accent_color) {
    const colors = generateBrandingColors(branding.value?.accent_color);

    document.documentElement.style.setProperty('--color-primary', colors.primaryBg);
    document.documentElement.style.setProperty('--color-primary-200', colors.primaryBgHover);
    document.documentElement.style.setProperty('--color-on-primary', colors.onPrimary);
    document.documentElement.style.setProperty('--color-primary-text', colors.primaryText);
    document.documentElement.style.setProperty('--color-primary-text-200', colors.primaryTextHover);

    document.documentElement.style.setProperty('--color-secondary', colors.primaryBg);
    document.documentElement.style.setProperty('--color-secondary-dark', colors.primaryBgHover);
    document.documentElement.style.setProperty('--color-secondary-light', colors.primaryBg);

    [16, 32, 96, 196].forEach((size) => {
      recolorFavicon(size, colors.primaryBg);
    });
  }
});

onBeforeMount(() => {
  if (!hasSubdomain()) {
    brandingStore.setBranding(config.value!.defaultBranding);
    brandingStore.setFields(locale.value, config.value!.defaultFields);
    return;
  }

  void getBranding({
    subdomain: subdomain.value,
    language: locale.value,
  });
});
</script>
