import { ref as c, readonly as C } from "vue";
var I = ((o) => (o[o.Customer = 1] = "Customer", o[o.Consumer = 2] = "Consumer", o[o.ConsumerERS = 3] = "ConsumerERS", o))(I || {}), d = { Info: "info", Success: "success", Warning: "warning", Error: "alert" }, N = { Toaster: "toaster" };
const t = c([]), a = c(0), E = 6e3;
function g() {
  const o = ({
    id: e,
    variant: i = d.Info,
    canClose: f,
    withShadow: l,
    body: v,
    title: m,
    timeout: r = E,
    location: T = N.Toaster
  }) => {
    let n = e;
    n || (a.value++, n = a.value.toString()), t.value.unshift({
      id: n,
      body: v,
      variant: i,
      title: m,
      timeout: r,
      location: T,
      canClose: f,
      withShadow: l
    }), r && setTimeout(() => {
      s(n);
    }, r);
  }, s = (e) => {
    t.value = t.value.filter((i) => i.id !== e);
  }, u = () => {
    t.value = [];
  };
  return {
    notifications: C(t),
    addNotification: o,
    removeNotification: s,
    clearNotifications: u
  };
}
export {
  g as useNotification
};
