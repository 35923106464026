export type HexColor = `#${string}`;

export type BrandingColors = {
  onPrimary: '#ffffff' | '#000000';
  primaryText: HexColor;
  primaryTextHover: HexColor;
  primaryBg: HexColor;
  primaryBgHover: HexColor;
};

export const minAPCAContrast = 40;

const maxColorValue = 255;
const hexidecimal = 16;

/**
 * Ensures the contrast between two colors is at least the provided value.
 * This uses the APCA contrast algorithm to calculate the contrast.
 *
 * @returns The adjusted foreground color with the required minimal contrast.
 * @example ensureAPCAContrast('#ffffff', '#ffff00', 40)
 */
export const ensureAPCAContrast = (backgroundHex: HexColor, foregroundHex: HexColor, minContrast: number) => {
  let adjustedHex = foregroundHex;
  let contrast = apcaContrast(backgroundHex, adjustedHex);
  let maxSteps = maxColorValue;

  while (contrast < minContrast && maxSteps-- > 0) {
    adjustedHex = changeColor(adjustedHex, -1);
    contrast = apcaContrast(backgroundHex, adjustedHex);
  }

  return adjustedHex;
};

export const apcaContrast = (hex1: HexColor, hex2: HexColor) => {
  const lum1 = hexToLuminance(hex1);
  const lum2 = hexToLuminance(hex2);
  return 100 * Math.abs(lum1 - lum2);
};

/**
 * Generates branding colors based on the provided hex color.
 * This used the APCA contrast algorithm to ensure the colors have a readable contrast ratio.
 * Text Color on Dark Background is White and on Light Background it is Black.
 *
 * @example generateBrandingColors('#ff0000')
 */
export function generateBrandingColors(hex: HexColor): BrandingColors {
  const luminance = hexToLuminance(hex);
  const stopsToHover = -50;
  const minimalLuminance = 0.5;

  if (luminance > minimalLuminance) {
    const primaryText = ensureAPCAContrast('#ffffff', hex, minAPCAContrast);
    const primaryTextHover = changeColor(primaryText, stopsToHover);

    return {
      onPrimary: '#000000',
      primaryText,
      primaryTextHover,
      primaryBg: hex,
      primaryBgHover: changeColor(hex, stopsToHover),
    };
  }

  return {
    onPrimary: '#ffffff',
    primaryText: hex,
    primaryTextHover: changeColor(hex, stopsToHover),
    primaryBg: hex,
    primaryBgHover: changeColor(hex, stopsToHover),
  };
}

/**
 * Converts a hex color to its luminance value.
 *
 * @example hexToLuminance('#ff0000') returns 0.2126 making it a dark color.
 * @example hexToLuminance('#00ff00') returns 0.7152 making it a light color.
 * @param hex - The hex color to convert.
 * @returns The luminance value of the hex color.
 */
export function hexToLuminance(hex: HexColor): number {
  const hexValue = hex.replace(/^#/, '');
  const endRed = 2;
  const endGreen = 4;
  const endBlue = 6;

  // Convert hex to RGB
  const red = parseInt(hexValue.substring(0, endRed), hexidecimal) / maxColorValue;
  const green = parseInt(hexValue.substring(endRed, endGreen), hexidecimal) / maxColorValue;
  const blue = parseInt(hexValue.substring(endGreen, endBlue), hexidecimal) / maxColorValue;

  // Calculate luminance using Photometric/digital ITU BT.709
  return 0.2126 * red + 0.7152 * green + 0.0722 * blue;
}

/**
 * Adjusts the brightness of a hex color.
 *
 * @example changeColor('#ff0000', 50) // returns '#ff3232', a lighter red
 *
 * @example changeColor('#00ff00', -50) // returns '#00cd00', a darker green
 *
 * @param {HexColor} hex - The hex color to change, expected to be in the format '#RRGGBB'.
 * @param {number} amount - The amount by which to change the color. Positive values make the color lighter,
 *                          negative values make the color darker.
 * @returns {HexColor} The new hex color with adjusted brightness, in the format '#RRGGBB'.
 */
export function changeColor(hex: HexColor, amount: number): HexColor {
  const clamp = (val: number) => Math.min(Math.max(val, 0), 0xff);
  const fill = (str: string) => `00${str}`.slice(-2);

  const num = parseInt(hex.substr(1), hexidecimal);
  const red = clamp((num >> hexidecimal) + amount);
  const green = clamp(((num >> 8) & 0x00ff) + amount);
  const blue = clamp((num & 0x0000ff) + amount);

  return `#${fill(red.toString(hexidecimal))}${fill(green.toString(hexidecimal))}${fill(blue.toString(hexidecimal))}` as HexColor;
}
