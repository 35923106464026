import {usePublicSdk} from '@myparcel-frontend/sdk';
import {CarrierId, type SocialShippingLink} from '@myparcel-frontend/types';

export const useSocialShippingLinks = () => {
  const getSocialShippingLinks = async (uuid: string) => {
    const {sdk} = usePublicSdk();

    const [result] = await sdk.getSocialShippingLinks({
      path: {uuid},
    });

    return result;
  };

  const putSocialShippingLinks = async (uuid: string, socialShippingLink: SocialShippingLink) => {
    const {sdk} = usePublicSdk();

    const data = {
      shop_id: socialShippingLink.shop_id,
      shipment_options: socialShippingLink.shipment_options,
    };

    // DPD needs to have drop off at postal point property set as 0.
    if (data.shipment_options.carrier === CarrierId.Dpd) {
      data.shipment_options.options.drop_off_at_postal_point = 0;
    }

    // Some carriers cannot have a delivery date property when using the shipping link.
    const carriersWithoutDeliveryDate = [CarrierId.Dpd, CarrierId.Bpost, CarrierId.DhlForYou];
    if (
      data.shipment_options.options?.delivery_date &&
      carriersWithoutDeliveryDate.includes(data.shipment_options.carrier)
    ) {
      delete data.shipment_options.options.delivery_date;
    }

    return sdk.putSocialShippingLinks({
      path: {uuid},
      body: [data],
    });
  };

  return {
    getSocialShippingLinks,
    putSocialShippingLinks,
  };
};
