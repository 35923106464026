<template>
  <div
    v-on-click-outside="closeOptions"
    class="cursor-pointer relative w-full"
    role="combobox"
    :name="name"
    :tabindex="disabled ? -1 : 0"
    :aria-disabled="disabled"
    :aria-expanded="isOpen"
    aria-haspopup="listbox"
    :aria-controls="`listbox-${name}`"
    :aria-activedescendant="isOpen ? `option-${optionValues[count]}` : undefined"
    @click="isOpen ? closeOptions() : openOptions()"
    @keydown.exact.space="spaceHandler"
    @keydown.exact.escape="closeOptions"
    @keydown.exact.prevent.up="upHandler"
    @keydown.exact.alt.prevent.up="upAltHandler"
    @keydown.exact.prevent.down="downHandler"
    @keydown.exact.enter="spaceHandler"
    @keydown.exact.prevent.end="endHandler"
    @keydown.exact.prevent.home="homeHandler"
    @keydown.exact.tab="tabHandler"
    @keydown.exact.prevent.page-down="pageDownHandler"
    @keydown.exact.prevent.page-up="pageUpHandler"
    @keydown="printableHandler">
    <div class="flex gap-2 p-2 w-full">
      <span>
        <slot
          name="value"
          :option="selectedOption">
          {{ selectedOption?.label ?? placeholder }}
        </slot>
      </span>
      <IconBase
        :size="IconSize.Medium"
        class="ml-auto self-center text-dark-light"
        :class="{'text-gray-800': disabled}">
        <ChevronDown24 v-if="!isOpen" />
        <ChevronUp24 v-else />
      </IconBase>
    </div>
    <div
      v-show="isOpen"
      :id="`listbox-${name}`"
      ref="selectBodyRef"
      role="listbox"
      tabindex="-1"
      class="absolute bg-white border hide-scrollbar max-h-52 overflow-auto py-2 rounded-[10px] shadow-card top-full w-full">
      <div
        v-for="(option, index) in options"
        :id="`option-${option.value}`"
        :key="option.value"
        class="border border-transparent cursor-pointer flex hover:bg-gray-200 p-2"
        :class="{
          ' ring ring-inset ring-blue-500': count === index,
        }"
        role="option"
        :selected="option.value === model"
        :aria-selected="option.value === model"
        @click.stop.prevent="selectItem(option)">
        <span>
          <slot
            name="option"
            :option="option">
            {{ option.label }}
          </slot>
        </span>
        <IconBase
          v-if="option.value === model"
          class="ml-auto self-center">
          <Check />
        </IconBase>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T extends Option">
import type {Option} from './Dropdown.types';
import {IconBase, IconSize, ChevronDown24, ChevronUp24, Check} from '@myparcel-frontend/ui';
import {vOnClickOutside} from '@vueuse/components';
import {useCounter} from '@vueuse/core';
import {computed, nextTick, ref, watch} from 'vue';

defineSlots<{
  value(props: {option: T | undefined}): never;
  option(props: {option: T | undefined}): never;
}>();

const props = defineProps<{
  modelValue: string;
  options: T[];
  disabled?: boolean;
  name: string;
  placeholder?: string;
}>();

const model = defineModel<T['value']>({required: true});
const isOpen = ref(false);

const {count, ...counter} = useCounter(0, {
  min: 0,
  max: props.options.length - 1,
});

const optionValues = computed(() => {
  return props.options.map((option) => option.value);
});

const selectedOption = computed(() => {
  return props.options.find((option) => option.value === model.value);
});

const openOptions = () => {
  if (props.disabled) return;

  if (model.value) {
    counter.set(optionValues.value.indexOf(model.value));
  }

  isOpen.value = true;
  nextTick(scrollOptionInView);
};

const closeOptions = () => {
  isOpen.value = false;
  counter.reset(0);
};

const selectItem = (option: Option) => {
  model.value = option.value;
  closeOptions();
};

// Keyboard handlers
// keyboard highlights should work different from the mouse highlights
// https://www.w3.org/WAI/ARIA/apg/patterns/combobox/examples/combobox-select-only/
const selectBodyRef = ref<HTMLDivElement>();

const scrollOptionInView = () => {
  // @ts-expect-error - TS doesn't know about children
  selectBodyRef.value.scrollTop = selectBodyRef.value.children[count.value].offsetTop;
};

watch(count, scrollOptionInView);

const downHandler = () => {
  if (!isOpen.value) {
    openOptions();
    return;
  }

  counter.inc(1);
};

const upHandler = () => {
  if (!isOpen.value) {
    openOptions();
    return;
  }

  counter.dec(1);
};

const upAltHandler = () => {
  if (!isOpen.value) return;

  selectItem(props.options[count.value]);
};

const spaceHandler = () => {
  if (!isOpen.value) {
    openOptions();
    return;
  }

  selectItem(props.options[count.value]);
};

const tabHandler = () => {
  if (!isOpen.value) return;

  selectItem(props.options[count.value]);
};

const homeHandler = () => {
  counter.set(0);

  if (!isOpen.value) {
    openOptions();
  }
};

const endHandler = () => {
  counter.set(props.options.length - 1);

  if (!isOpen.value) {
    openOptions();
  }
};

const history = ref<string[]>([]);
const lastPressed = ref<string>('');

let timer: NodeJS.Timeout | null = null;

const clearHistryInterval = 500;

const startHistoryTimer = () => {
  timer = setTimeout(() => {
    history.value = [];
  }, clearHistryInterval);
};

const printableHandler = (event: KeyboardEvent) => {
  // todo add number support
  const isPrintable = event.key.length === 1 && event.key.match(/[a-z]/i);

  if (!isPrintable) return;

  if (timer) clearTimeout(timer);

  startHistoryTimer();

  history.value.push(event.key);

  if (!isOpen.value) {
    openOptions();
  }

  //  when the same key is pressed cycle through the options
  if (lastPressed.value === event.key) {
    const matchingOptions = props.options.filter((option) =>
      option.label.toLowerCase().startsWith(lastPressed.value.toLowerCase()),
    );

    if (matchingOptions.length === 1 || matchingOptions.length === 0) {
      return;
    }

    const currentIndex = optionValues.value.indexOf(props.options[count.value].value);
    const firstMatchingOption = optionValues.value.indexOf(matchingOptions[0].value);
    const lastMatchingOption = optionValues.value.indexOf(matchingOptions[matchingOptions.length - 1].value);

    if (currentIndex >= lastMatchingOption) {
      counter.set(firstMatchingOption);
    } else {
      counter.inc(1);
    }

    return;
  }

  // select the first option that starts with the typed letters
  const stringToSearch = history.value.join('').toLowerCase();
  const matchingOption = props.options.find((option) => option.label.toLowerCase().startsWith(stringToSearch));

  if (!matchingOption) return;

  // find the index of the matchingOption
  const index = optionValues.value.indexOf(matchingOption.value);
  counter.set(index);
  lastPressed.value = event.key;
};

/**
 * Page up and page down handlers
 * Page size is 10 as a standard increment and decrement by this number.
 */
const PAGE_SIZE = 10;

const pageDownHandler = () => {
  if (!isOpen.value) return;

  counter.inc(PAGE_SIZE);
};

const pageUpHandler = () => {
  if (!isOpen.value) return;

  counter.dec(PAGE_SIZE);
};
</script>
