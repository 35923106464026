import type {ReturnReason, ReturnReasonSettings} from '@myparcel-frontend/types';
import {usePublicSdk} from '@myparcel-frontend/sdk';
import {ref, onUnmounted} from 'vue';
import {useConfig} from '@/composables/useConfig/useConfig';

const settings = ref<ReturnReasonSettings | null>(null);

// todo this is closely related to return shipments do we want to combine these composables?
export const useReturnReasons = () => {
  const {subdomain} = useConfig();

  const getReturnSettings = async (hash: string) => {
    const {sdk} = usePublicSdk();

    // todo make subdomain dynamic
    const [result] = await sdk.getReturnReasonSettings({
      parameters: {
        hash,
        subdomain: subdomain.value,
      },
    });

    // Move "other" reason to the bottom.
    const sotredReasons = [...result.return_reasons];
    sotredReasons.push(
      ...sotredReasons.splice(
        sotredReasons.findIndex((item) => item.name === 'other'),
        1,
      ),
    );

    return {
      ...result,
      return_reasons: sotredReasons,
    };
  };

  const getReturnReasons = (hash: string) => {
    const {sdk} = usePublicSdk();

    return sdk.getReturnShipmentReturnReason({
      path: {
        hash,
      },
    });
  };

  const createReturnReasons = (hash: string, reasons: ReturnReason[]) => {
    const {sdk} = usePublicSdk();
    return sdk.postReturnShipmentReturnReasons({
      path: {
        hash,
      },
      body: reasons,
    });
  };

  const createReturnComment = (hash: string, comment: string) => {
    const {sdk} = usePublicSdk();
    return sdk.postReturnShipmentReturnComment({
      path: {
        hash,
      },
      body: [
        {
          text: comment,
        },
      ],
    });
  };

  onUnmounted(() => {
    settings.value = null;
  });

  return {
    settings,
    getReturnSettings,
    getReturnReasons,
    createReturnReasons,
    createReturnComment,
  };
};
