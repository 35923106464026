import { defineComponent as z, useCssVars as D, computed as f, openBlock as i, createElementBlock as m, normalizeClass as b, unref as r, createElementVNode as _, createVNode as k, withCtx as d, createBlock as p, createCommentVNode as h, renderSlot as C, ref as $, readonly as U, Fragment as L, renderList as O, createSlots as A, createTextVNode as T, toDisplayString as I, TransitionGroup as G } from "vue";
import { IconBase as E, IconSize as F, Info as M, Error as R, CheckRounded as j, Times as q } from "@myparcel-frontend/ui";
const y = {
  Info: "info",
  Success: "success",
  Warning: "warning",
  Error: "alert"
}, S = {
  Full: "full",
  Half: "half"
}, H = {
  Toaster: "toaster"
}, J = { key: 0 }, K = { class: "content flex flex-col grow" }, P = { class: "empty:hidden font-bold" }, Q = { class: "body" }, X = /* @__PURE__ */ z({
  __name: "Toast",
  props: {
    id: {},
    variant: {},
    timeout: { default: 6e3 },
    size: { default: S.Full },
    canClose: { type: Boolean },
    withShadow: { type: Boolean }
  },
  emits: ["remove"],
  setup(c, { emit: u }) {
    D((l) => ({
      "63af09fa": n.value
    }));
    const o = c, s = u;
    o.timeout && setTimeout(() => {
      s("remove", o.id);
    }, o.timeout);
    const n = f(() => `${o.timeout}ms`), e = f(() => o.variant === y.Info), t = f(() => o.variant === y.Error), a = f(() => o.variant === y.Warning), v = f(() => o.variant === y.Success);
    return (l, N) => (i(), m("div", {
      class: b(["border border-gray-400 flex items-center mb-4 md:text-base overflow-hidden p-4 pointer-events-auto relative rounded-lg transform-gpu", {
        "drop-shadow-md": l.withShadow,
        "!pb-5": !!l.timeout,
        "md:min-w-[400px] md:max-w-3xl md:min-h-[56px]": l.size === r(S).Half,
        "bg-green-100": v.value,
        "bg-blue-100": e.value,
        "bg-yellow-100": a.value,
        "bg-red-100": t.value
      }]),
      role: "status",
      "aria-live": "polite",
      "aria-atomic": "true",
      "data-test": "toast"
    }, [
      l.timeout ? (i(), m("div", J, [
        _("div", {
          class: b(["absolute bottom-0 h-1 left-0 origin-left toast__timer w-full", {
            "bg-blue": e.value,
            "bg-green": v.value,
            "bg-yellow": a.value,
            "bg-red": t.value
          }])
        }, null, 2),
        _("div", {
          class: b(["absolute bottom-0 h-1 left-0 opacity-20 w-full", {
            "bg-blue": e.value,
            "bg-green": v.value,
            "bg-yellow": a.value,
            "bg-red": t.value
          }])
        }, null, 2)
      ])) : (i(), m("div", {
        key: 1,
        class: b(["absolute h-full left-0 top-0 w-1", {
          "bg-blue": e.value,
          "bg-green": v.value,
          "bg-yellow": a.value,
          "bg-red": t.value
        }])
      }, null, 2)),
      _("div", {
        class: b(["flex-none pr-4", {
          "text-blue": e.value,
          "text-green": v.value,
          "text-yellow": a.value,
          "text-red": t.value
        }])
      }, [
        k(r(E), {
          size: r(F).Normal
        }, {
          default: d(() => [
            e.value ? (i(), p(r(M), { key: 0 })) : h("", !0),
            t.value ? (i(), p(r(R), { key: 1 })) : h("", !0),
            a.value ? (i(), p(r(R), { key: 2 })) : h("", !0),
            v.value ? (i(), p(r(j), { key: 3 })) : h("", !0)
          ]),
          _: 1
        }, 8, ["size"])
      ], 2),
      _("div", K, [
        _("div", P, [
          C(l.$slots, "title", {}, void 0, !0)
        ]),
        _("div", Q, [
          C(l.$slots, "default", {}, void 0, !0)
        ])
      ]),
      l.canClose ? (i(), m("button", {
        key: 2,
        type: "button",
        class: "button close flex-none pl-4",
        onClick: N[0] || (N[0] = (w) => l.$emit("remove", l.id))
      }, [
        k(r(E), {
          size: r(F).Normal,
          class: "cursor-pointer"
        }, {
          default: d(() => [
            k(r(q))
          ]),
          _: 1
        }, 8, ["size"])
      ])) : h("", !0)
    ], 2));
  }
}), x = (c, u) => {
  const o = c.__vccOpts || c;
  for (const [s, n] of u)
    o[s] = n;
  return o;
}, B = /* @__PURE__ */ x(X, [["__scopeId", "data-v-e48d5055"]]), g = $([]), V = $(0), Y = 6e3;
function W() {
  const c = ({
    id: s,
    variant: n = y.Info,
    canClose: e,
    withShadow: t,
    body: a,
    title: v,
    timeout: l = Y,
    location: N = H.Toaster
  }) => {
    let w = s;
    w || (V.value++, w = V.value.toString()), g.value.unshift({
      id: w,
      body: a,
      variant: n,
      title: v,
      timeout: l,
      location: N,
      canClose: e,
      withShadow: t
    }), l && setTimeout(() => {
      u(w);
    }, l);
  }, u = (s) => {
    g.value = g.value.filter((n) => n.id !== s);
  }, o = () => {
    g.value = [];
  };
  return {
    notifications: U(g),
    addNotification: c,
    removeNotification: u,
    clearNotifications: o
  };
}
const Z = ["data-test"], ee = /* @__PURE__ */ z({
  __name: "LocationContainer",
  props: {
    location: {}
  },
  setup(c) {
    const { notifications: u, removeNotification: o } = W(), s = c, n = f(() => u.value.filter((e) => e.location === s.location));
    return (e, t) => (i(), m("div", {
      "data-test": `notifications ${e.location}`
    }, [
      (i(!0), m(L, null, O(n.value, (a) => (i(), p(B, {
        id: a.id ?? "",
        key: a.id,
        size: r(S).Full,
        timeout: a.timeout,
        variant: a.variant,
        "can-close": a.canClose,
        "with-shadow": a.withShadow,
        class: "flex-none",
        onRemove: r(o)
      }, A({
        default: d(() => [
          T(" " + I(a.body), 1)
        ]),
        _: 2
      }, [
        a.title ? {
          name: "title",
          fn: d(() => [
            T(I(a.title), 1)
          ]),
          key: "0"
        } : void 0
      ]), 1032, ["id", "size", "timeout", "variant", "can-close", "with-shadow", "onRemove"]))), 128))
    ], 8, Z));
  }
}), se = /* @__PURE__ */ x(ee, [["__scopeId", "data-v-e9f1f236"]]), ne = /* @__PURE__ */ z({
  __name: "Notification",
  props: {
    id: {},
    variant: {},
    canClose: { type: Boolean },
    withShadow: { type: Boolean },
    timeout: { default: 0 }
  },
  emits: ["remove"],
  setup(c, { emit: u }) {
    const o = u, s = $(!0), n = () => {
      s.value = !1, o("remove");
    };
    return (e, t) => s.value ? (i(), p(B, {
      key: 0,
      id: e.id,
      class: "relative",
      variant: e.variant,
      "can-close": e.canClose,
      "with-shadow": e.withShadow,
      timeout: e.timeout,
      "data-test": "notification",
      onRemove: n
    }, {
      title: d(() => [
        C(e.$slots, "title")
      ]),
      default: d(() => [
        C(e.$slots, "default")
      ]),
      _: 3
    }, 8, ["id", "variant", "can-close", "with-shadow", "timeout"])) : h("", !0);
  }
}), te = { id: "notification__container" }, oe = /* @__PURE__ */ z({
  __name: "NotificationContainer",
  setup(c) {
    const { notifications: u, removeNotification: o } = W(), s = f(() => u.value.filter(
      (n) => n.location === H.Toaster
    ));
    return (n, e) => (i(), m("div", te, [
      k(G, { name: "list" }, {
        default: d(() => [
          (i(!0), m(L, null, O(s.value, (t) => (i(), p(B, {
            id: t.id ?? "",
            key: t.id,
            size: r(S).Half,
            timeout: t.timeout ?? void 0,
            variant: t.variant,
            "can-close": t.canClose,
            "with-shadow": t.withShadow,
            class: "flex-none",
            onRemove: r(o)
          }, A({
            default: d(() => [
              T(" " + I(t.body), 1)
            ]),
            _: 2
          }, [
            t.title ? {
              name: "title",
              fn: d(() => [
                T(I(t.title), 1)
              ]),
              key: "0"
            } : void 0
          ]), 1032, ["id", "size", "timeout", "variant", "can-close", "with-shadow", "onRemove"]))), 128))
        ]),
        _: 1
      })
    ]));
  }
}), le = /* @__PURE__ */ x(oe, [["__scopeId", "data-v-1fadfd64"]]);
export {
  se as LocationContainer,
  ne as Notification,
  le as NotificationContainer,
  H as NotificationLocation,
  S as NotificationSize,
  y as NotificationVariant,
  W as useNotification
};
